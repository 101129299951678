import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "text-center text-2xl font-bold" }
const _hoisted_2 = { class: "underline text-black-100" }
const _hoisted_3 = { class: "underline text-black-100" }
const _hoisted_4 = { class: "underline text-black-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_dialog = _resolveComponent("el-dialog")!

  return (_openBlock(), _createBlock(_component_el_dialog, {
    class: "rounded-lg",
    modelValue: $setup.DialogModule.isToggle,
    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (($setup.DialogModule.isToggle) = $event)),
    width: "40%",
    center: "",
    "before-close": $setup.handleClose
  }, {
    title: _withCtx(() => [
      _createElementVNode("h1", _hoisted_1, _toDisplayString($setup.initialValues.title), 1)
    ]),
    footer: _withCtx(() => [
      _createElementVNode("div", { class: "flex justify-center items-center space-x-4" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--gray",
          onClick: $setup.handleClose
        }, " Cancel "),
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--green-primary",
          onClick: $setup.handleConfirm
        }, " Yes ")
      ])
    ]),
    default: _withCtx(() => [
      _createElementVNode("h1", {
        class: _normalizeClass(['text-center 2xl:text-lg', _ctx.styles['keep-all']])
      }, [
        _cache[1] || (_cache[1] = _createTextVNode(" You have already set a ")),
        _createElementVNode("span", _hoisted_2, _toDisplayString($setup.targetName.toString().replace(",", ", ")), 1),
        _cache[2] || (_cache[2] = _createTextVNode(" for ")),
        _createElementVNode("span", _hoisted_3, _toDisplayString($setup.facilityName.length > 0
          ? $setup.facilityName.toString().replace(",", ", ")
          : "all facilities"), 1),
        _cache[3] || (_cache[3] = _createTextVNode(" in ")),
        _createElementVNode("span", _hoisted_4, _toDisplayString($setup.countryName.length > 0
          ? $setup.countryName.toString().replace(",", ", ")
          : "all regions"), 1),
        _cache[4] || (_cache[4] = _createTextVNode(". If you proceed with this goal, the existing goal will be inactivated. "))
      ], 2)
    ]),
    _: 1
  }, 8, ["modelValue"]))
}