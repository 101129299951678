import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, mergeProps as _mergeProps, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, withModifiers as _withModifiers, resolveDirective as _resolveDirective } from "vue"

const _hoisted_1 = { class: "my-4 px-10 py-1" }
const _hoisted_2 = { class: "flex space-x-4 mb-3" }
const _hoisted_3 = { class: "font-bold" }
const _hoisted_4 = { class: "box my-10 py-5" }
const _hoisted_5 = { class: "grid grid-cols-1 lg:grid-cols-3 gap-y-4 gap-x-12 mt-3 xl:grid-cols-2 2xl:grid-cols-3" }
const _hoisted_6 = { class: "grid grid-cols-1 lg:grid-cols-3 gap-y-4 gap-x-12 mt-3 xl:grid-cols-2 2xl:grid-cols-3" }
const _hoisted_7 = { class: "grid grid-cols-1 lg:grid-cols-2" }
const _hoisted_8 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_9 = { class: "flex relative" }
const _hoisted_10 = { class: "cursor-pointer group hover:text-blue-500 text-2xl ml-1" }
const _hoisted_11 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_12 = { class: "grid grid-cols-1 lg:grid-cols-2" }
const _hoisted_13 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_14 = { class: "flex" }
const _hoisted_15 = { class: "cursor-pointer group hover:text-blue-500 text-2xl ml-1" }
const _hoisted_16 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_17 = { class: "cursor-pointer group hover:text-blue-500 text-2xl ml-1" }
const _hoisted_18 = { class: "grid grid-cols-1 lg:grid-cols-2" }
const _hoisted_19 = { class: "flex relative" }
const _hoisted_20 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_21 = { class: "grid grid-cols-1 lg:grid-cols-2" }
const _hoisted_22 = { class: "cursor-pointer group hover:text-blue-500 text-2xl ml-1" }
const _hoisted_23 = {
  key: 0,
  class: "el-form-item__error"
}
const _hoisted_24 = { class: "flex justify-end items-center border-t-2 2xl:space-x-5 space-x-3 2xl:py-5 py-3" }
const _hoisted_25 = ["disabled"]
const _hoisted_26 = { key: 0 }

import { Field, Form, configure } from "vee-validate";
import { object, string } from "yup";
import { ref, onMounted, computed, watch, reactive } from "vue";
import { useRoute, useRouter } from "vue-router";
import debounce from "lodash/debounce";
import dayjs from "dayjs";
import RenewableEnergyTargetModule from "@ems/containers/RenewableEnergyTarget/RenewableEnergyTarget.module";
import RenewableEnergyTargetTableBenchMark from "@ems/containers/RenewableEnergyTarget/Table/index.vue";
import isEmpty from "lodash/isEmpty";
import BaseSvgIcon from "@/components/BaseSvgIcon.vue";
import MetadataModule from "@/store/modules/Metadata";
import DialogVerifyVue from "@ems/containers/RenewableEnergyTarget/Dialog/DialogVerify.vue";
import DialogModule from "@/store/UI/Dialog";
import { startDateOfMonth } from "@/utils/helpers/convertDateTime";


export default /*@__PURE__*/_defineComponent({
  __name: 'PublishNewTarget',
  setup(__props) {

const TOOLTIP_DATA = {
  LONG_TERM: `Targets to actively source renewable electricity at a rate that is consistent with 1.5°C scenarios are an acceptable alternative to scope 2 emission reduction targets.

   The SBTi has identified 80% renewable electricity procurement by 2025 and 100% by 2030 as thresholds for this approach in line with the recommendations of RE100.`,
  SHORT_TERM: `Targets to actively source renewable electricity at a rate that is consistent with 1.5°C scenarios are an acceptable alternative to scope 2 emission reduction targets. 

  The SBTi has identified 80% renewable electricity procurement by 2025 and 100% by 2030 as thresholds for this approach in line with the recommendations of RE100.`,
  TRACKING: `GHG Protocol requires measuring and reporting scope 2 emissions using both approaches. However, a single and consistent approach shall be used for setting and tracking progress toward a SBT`,
  BASE_YEAR: `Targets must cover a minimum of 5 years and a maximum of 10 years from the date the target is submitted.

  For targets submitted for an official validation in the first half of 2022, the valid target years are 2026-2031 inclusive.
  
  For targets submitted in the second half of 2022, the valid target years are between 2027 and 2032 inclusive.`,
};

const LIMIT_TIME = {
  MAX_YEAR: 11,
  MIN_YEAR: 5,
  MAX_MONTH: 6,
  MIN_MONTH: 5,
};
configure({
  validateOnBlur: false,
  validateOnChange: false,
  validateOnInput: false,
  validateOnModelUpdate: true,
});
const errorMessageRef = ref([] as any);
const isCheckSubmitted = ref(false);
const isOpenSubmit = ref(true);
const standardTypeSelected = ref(0);

const route = useRoute();
const router = useRouter();
const longTermTargetValueField = ref();
const longTermTargetYearField = ref();
const facilityIdField = ref();
const countryIdField = ref();
const schema = object({
  TargetName: string().required().label("Target Name"),
  LongTermTargetYear: string().required().label("Target Year"),
  LongTermTargetValue: string().required().label("Renewable Percentage Target"),
  FacilityId: string().when("CountryId", {
    is: () => countryIdField.value !== null,
    then: string().required("Facility is a required field"),
    otherwise: string(),
  }),
});

const onValidateLongTermTarget = debounce(async (value, field, dataForm) => {
  if (standardTypeSelected.value === 0) return;
  if (convertToNumber(value) >= 100 || !value) {
    errorMessageRef.value[field.name] = "";
  } else {
    errorMessageRef.value[
      field.name
    ] = `If target fall below 100% for ${dataForm.LongTermTargetValue}, it’s no longer in consistence with SBTi 1.5°C ambition`;
  }

  if (standardTypeSelected.value) {
    handleCheckOpenSubmit(dataForm);
  }
}, 100);

const onValidateShortTermTarget = debounce(async (value, field, dataForm) => {
  if (convertToNumber(value) >= 80 || !value) {
    errorMessageRef.value[field.name] = "";
  } else {
    errorMessageRef.value[
      field.name
    ] = `If target fall below 80% for ${dataForm.LongTermTargetValue}, it’s no longer in consistence with SBTi 1.5°C ambition`;
  }
}, 100);

const handleStandardType = (value: any, setFieldValue: any) => {
  standardTypeSelected.value = value;
  longTermTargetValueField.value.reset();
  longTermTargetYearField.value.reset();
  setFieldValue("LongTermTargetYear", "");
  setFieldValue("LongTermTargetValue", "");
  setFieldValue("ShortTermTargetYear", "");
  setFieldValue("ShortTermTargetValue", "");
  setFieldValue("TrackingMethodType", "");
  setFieldValue("BaseYear", "");
  setFieldValue("BaseYearTargetValue", "");
  setFieldValue("MonthOfTargetSubmission", "");
  isCheckSubmitted.value = false;
  errorMessageRef.value = [];
};

const handleCheckOpenSubmit = (dataForm: any) => {
  if (
    (dataForm.TrackingMethodType > -1 &&
      dataForm.MonthOfTargetSubmission &&
      dataForm.BaseYearTargetValue &&
      dataForm.BaseYear &&
      dataForm.StandardType &&
      !errorMessageRef.value.ShortTermTargetYear &&
      !errorMessageRef.value.MonthOfTargetSubmission) ||
    dataForm.StandardType === 0
  ) {
    isOpenSubmit.value = true;
  } else {
    isOpenSubmit.value = false;
  }
};

const onValidateShortTermYear = (
  value: any,
  field: any,
  dataForm: any,
  setFieldValue: any
) => {
  if (
    convertToNumber(dataForm.LongTermTargetYear) >= convertToNumber(value) ||
    !value ||
    !dataForm.LongTermTargetYear
  ) {
    errorMessageRef.value[field.name] = "";
    errorMessageRef.value["LongTermTargetYear"] = "";
  } else {
    errorMessageRef.value[field.name] =
      "This year need to smaller than long term year";
    errorMessageRef.value["LongTermTargetYear"] =
      "This year need to bigger than short term year";
    isOpenSubmit.value = false;
  }

  if (value) {
    setFieldValue("ShortTermTargetValue", 80);
  } else {
    setFieldValue("ShortTermTargetValue", "");
    handleCheckOpenSubmit(dataForm);
  }
};

const onValidateLongTermYear = (
  value: any,
  field: any,
  dataForm: any,
  setFieldValue: any
) => {
  if (dataForm.StandardType) {
    setFieldValue("LongTermTargetValue", 100);
    handleMonthTarget(dataForm.MonthOfTargetSubmission, dataForm);
  }

  if (
    convertToNumber(dataForm.ShortTermTargetYear) <= convertToNumber(value) ||
    !value ||
    !dataForm.ShortTermTargetYear
  ) {
    errorMessageRef.value[field.name] = "";
    errorMessageRef.value["ShortTermTargetYear"] = "";
  } else {
    errorMessageRef.value[field.name] =
      "This year need to bigger than short term year";
    errorMessageRef.value["ShortTermTargetYear"] =
      "This year need to smaller than long term year";
    isOpenSubmit.value = false;
  }
};

const disabledBaseYear = (time: Date) => {
  return (
    time.getTime() > Date.now() ||
    time.getTime() < dayjs().year(2015).toDate().getTime()
  );
};

const disabledMonthSubmitted = (time: Date) => {
  return isCheckSubmitted.value
    ? time.getTime() > Date.now()
    : time.getTime() < Date.now();
};

const disabledLongTermYear = (time: Date) => {
  if (dayjs().month() < 6) {
    return (
      time.getTime() > dayjs().add(9, "years").toDate().getTime() ||
      time.getTime() < Date.now()
    );
  }
  return (
    time.getTime() > dayjs().add(10, "years").toDate().getTime() ||
    time.getTime() < Date.now()
  );
};

const disabledShortTermYear = (time: Date) => {
  if (dayjs().month() < 6) {
    return (
      time.getTime() > dayjs().add(4, "years").toDate().getTime() ||
      time.getTime() < Date.now()
    );
  }
  return (
    time.getTime() > dayjs().add(5, "years").toDate().getTime() ||
    time.getTime() < Date.now()
  );
};

const handleMonthTarget = (value: any, dataForm: any) => {
  const selectedYear = dayjs(value).toDate().getFullYear();
  const date1 = dayjs(dataForm.LongTermTargetYear);
  let conditionMinDate = true;
  conditionMinDate = date1.diff(value, "month") <= 48;

  if (conditionMinDate || !dataForm.LongTermTargetYear) {
    errorMessageRef.value["MonthOfTargetSubmission"] = "";
  } else {
    if (!selectedYear) return;
    errorMessageRef.value["MonthOfTargetSubmission"] =
      "Targets must cover a minimum of 5 years and a maximum of 10 years from the date the target is submitted.  For targets submitted for an official validation in the first half of 2022, the valid target years are 2026-2031 inclusive. For targets submitted in the second half of 2022, the valid target years are between 2027 and 2032 inclusive.";
  }

  handleCheckOpenSubmit(dataForm);
};

const handleCheckSubmitted = (val: boolean, setFieldValue: any) => {
  isCheckSubmitted.value = val;
  setFieldValue(`MonthOfTargetSubmission`, "");
  errorMessageRef.value.MonthOfTargetSubmission = "";
};

const handleCancel = async () => {
  errorMessageRef.value = [];
  router.push("/renewable-energy-target-management");
};

const dataDetail = computed(
  () => RenewableEnergyTargetModule.dataRenewableTargetDetailGetter
);

const { TargetId }: { TargetId?: string } = route.query;
const facilities = reactive<any>({
  data: [],
});

onMounted(async () => {
  RenewableEnergyTargetModule.getTrackingMethod();
  RenewableEnergyTargetModule.getStandards();
  MetadataModule.getAllCountryAction();

  if (TargetId) {
    await RenewableEnergyTargetModule.getRenewableTargetDetail(TargetId);
    getSelectedFacility(dataDetail.value.CountryId);
  }
});

const getListTracking = computed(
  () => RenewableEnergyTargetModule.listDataTrackingMethodsGetter
);
const getListStandards = computed(
  () => RenewableEnergyTargetModule.listDataStandardsGetter
);

const loadingGetRenewableTargetDetail = computed(
  () => RenewableEnergyTargetModule.isLoadingGetRenewableTargetDetailGetter
);

const onSubmit = async (values: any) => {
  let facilityActivated = false;

  facilityActivated = await RenewableEnergyTargetModule.verifyFacilityActive({
    FacilityId: values.FacilityId === "All" ? null : values.FacilityId,
    StandardType: values.StandardType ? values.StandardType : 0,
  });
  if (!isEmpty(facilityActivated)) {
    handleActiveNewTarget(values, facilityActivated);
  } else {
    publishTarget(values);
  }
};

const publishTarget = async (values: any) => {
  let formatData = {} as any;

  if (values.StandardType === 0 || standardTypeSelected.value === 0) {
    formatData = {
      TargetName: values.TargetName,
      LongTermTargetYear: convertToNumber(values.LongTermTargetYear),
      LongTermTargetValue: convertToNumber(values.LongTermTargetValue),
      FacilityId: values.FacilityId === "All" ? null : values.FacilityId,
      CountryId: values.CountryId,
      StandardType: 0,
    };
  } else {
    formatData = {
      ...values,
      LongTermTargetYear: convertToNumber(values.LongTermTargetYear),
      LongTermTargetValue: convertToNumber(values.LongTermTargetValue),
      ShortTermTargetYear: convertToNumber(values.ShortTermTargetYear),
      ShortTermTargetValue: convertToNumber(values.ShortTermTargetValue),
      BaseYear: convertToNumber(values.BaseYear),
      BaseYearTargetValue: convertToNumber(values.BaseYearTargetValue),
      FacilityId: values.FacilityId === "All" ? null : values.FacilityId,
      MonthOfTargetSubmission: values.MonthOfTargetSubmission
        ? startDateOfMonth(values.MonthOfTargetSubmission)
        : null,
      IsSubmitted: isCheckSubmitted.value,
    };
  }
  if (isEmpty(dataDetail.value)) {
    RenewableEnergyTargetModule.createRenewableTarget(formatData);
  } else {
    await RenewableEnergyTargetModule.updateRenewableTargetDetail({
      id: TargetId as string,
      data: formatData,
    });
  }
  await RenewableEnergyTargetModule.getRenewableTarget();
  router.push("/renewable-energy-target-management");
};

const handleActiveNewTarget = (values: any, facilityActivated: any) => {
  const oldCountryName = dataCountries.value.find(
    (item) => item.Value === facilityActivated.CountryId
  ) as any;
  const oldFacilityName = facilities.data.find(
    (item: any) => item.Value === facilityActivated.FacilityId
  ) as any;

  DialogModule.showModal({
    component: DialogVerifyVue,
    data: {
      title: "Replace previous goal?",
      facilityActivated,
      oldFacilityName,
      oldCountryName,
    },
    rest: () => publishTarget(values),
  });
};

const dataCountries = computed(
  () => MetadataModule.dataInputManualCountriesGetter
);
const dataFacilityDefault = [
  {
    CountryId: "",
    CountryName: "",
    CountryCode: "",
    Red: false,
    Url: "",
    Latitude: 0,
    Longitude: 0,
    Value: "All",
    Name: "All",
  } as any,
];
const handleChangeCountry = (value: any, setFieldValue: any) => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  if (value === null) {
    facilities.data = [
      ...dataFacilityDefault,
      ...facilitiesGetter.filter((fac) => fac.CountryId == value),
    ];
  } else {
    facilities.data = facilitiesGetter.filter((fac) => fac.CountryId == value);
  }

  facilityIdField.value.reset();
};

const getSelectedFacility = (CountryId: string) => {
  const facilitiesGetter = MetadataModule.dataFacilitiesGetter;
  facilities.data = [
    ...dataFacilityDefault,
    ...facilitiesGetter.filter((fac) => fac.CountryId === CountryId),
  ];
};

watch(dataDetail.value, () => {
  isCheckSubmitted.value = dataDetail.value.IsSubmitted;
  handleCheckOpenSubmit(dataDetail.value);
});

const convertToNumber = (text: any) => {
  return text - 0;
};
const convertToString = (number: any) => {
  return number + "";
};

return (_ctx: any,_cache: any) => {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_form_item = _resolveComponent("el-form-item")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_date_picker = _resolveComponent("el-date-picker")!
  const _component_el_tooltip = _resolveComponent("el-tooltip")!
  const _component_el_checkbox = _resolveComponent("el-checkbox")!
  const _directive_loading = _resolveDirective("loading")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("ol", _hoisted_2, [
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: "/" }, {
          default: _withCtx(() => _cache[4] || (_cache[4] = [
            _createTextVNode("Home")
          ])),
          _: 1
        })
      ]),
      _cache[7] || (_cache[7] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", null, [
        _createVNode(_component_router_link, { to: `/renewable-energy-target-management` }, {
          default: _withCtx(() => _cache[5] || (_cache[5] = [
            _createTextVNode(" Renewable Energy Target Management ")
          ])),
          _: 1
        })
      ]),
      _cache[8] || (_cache[8] = _createElementVNode("li", null, ">", -1)),
      _createElementVNode("li", _hoisted_3, [
        _createVNode(_component_router_link, { to: "" }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" Publish New Target ")
          ])),
          _: 1
        })
      ])
    ]),
    _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_4, [
      _cache[13] || (_cache[13] = _createElementVNode("p", { class: "2xl:text-xl text-lg font-bold" }, "Publish New Target", -1)),
      (!_unref(TargetId) || !_unref(isEmpty)(dataDetail.value))
        ? (_openBlock(), _createBlock(_unref(Form), {
            key: 0,
            as: "el-form",
            "validation-schema": _unref(schema),
            "initial-values": dataDetail.value,
            onSubmit: onSubmit,
            "label-position": "top"
          }, {
            default: _withCtx(({ isSubmitting, setFieldValue, values }) => [
              _createElementVNode("div", {
                class: _normalizeClass(isSubmitting && 'isSubmitting-rounded')
              }, [
                _createElementVNode("div", null, [
                  _createElementVNode("div", _hoisted_5, [
                    _createVNode(_unref(Field), { name: "TargetName" }, {
                      default: _withCtx(({ value, field, errorMessage }) => [
                        _createVNode(_component_el_form_item, {
                          class: "inputfield",
                          error: errorMessage,
                          label: "Target Name"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, { "model-value": value }), null, 16, ["model-value"])
                          ]),
                          _: 2
                        }, 1032, ["error"])
                      ]),
                      _: 1
                    }),
                    _createVNode(_unref(Field), { name: "CountryId" }, {
                      default: _withCtx(({ value, field, errorMessage }) => [
                        _createVNode(_component_el_form_item, {
                          ref_key: "countryIdField",
                          ref: countryIdField,
                          class: "inputfield",
                          error: errorMessage,
                          label: "Applicable Region",
                          "model-value": value
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, _mergeProps({ class: "w-full" }, field, {
                              "model-value": value,
                              placeholder: "All company operating region",
                              onChange: (value) => handleChangeCountry(value, setFieldValue),
                              disabled: !_unref(isEmpty)(dataDetail.value)
                            }), {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(dataCountries.value, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    class: "flex items-center",
                                    key: item.Value,
                                    label: item.Name,
                                    value: item.Value
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1040, ["model-value", "onChange", "disabled"])
                          ]),
                          _: 2
                        }, 1032, ["error", "model-value"])
                      ]),
                      _: 2
                    }, 1024),
                    _createVNode(_unref(Field), {
                      ref_key: "facilityIdField",
                      ref: facilityIdField,
                      name: "FacilityId"
                    }, {
                      default: _withCtx(({ value, field, errorMessage }) => [
                        _createVNode(_component_el_form_item, {
                          class: "inputfield",
                          error: errorMessage,
                          label: "Applicable Facilities",
                          "model-value": value
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, _mergeProps({ class: "w-full" }, field, {
                              "model-value": value,
                              placeholder: "All company operating facilities",
                              disabled: !_unref(isEmpty)(dataDetail.value)
                            }), {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(facilities.data, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    class: "flex items-center",
                                    key: item.Value,
                                    label: item.Name,
                                    value: item.Value
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1040, ["model-value", "disabled"])
                          ]),
                          _: 2
                        }, 1032, ["error", "model-value"])
                      ]),
                      _: 1
                    }, 512),
                    _createVNode(_unref(Field), { name: "StandardType" }, {
                      default: _withCtx(({ value, field, errorMessage }) => [
                        _createVNode(_component_el_form_item, {
                          class: "inputfield",
                          error: errorMessage,
                          label: "Choose Reporting Standards"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(_component_el_select, _mergeProps(field, {
                              "model-value": value,
                              class: "w-full",
                              disabled: !_unref(isEmpty)(dataDetail.value),
                              onChange: (value) => handleStandardType(value, setFieldValue),
                              placeholder: "Please Select Reporting Standard"
                            }), {
                              default: _withCtx(() => [
                                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getListStandards.value, (item) => {
                                  return (_openBlock(), _createBlock(_component_el_option, {
                                    key: item.Value,
                                    label: item.Name,
                                    value: item.Value
                                  }, null, 8, ["label", "value"]))
                                }), 128))
                              ]),
                              _: 2
                            }, 1040, ["model-value", "disabled", "onChange"])
                          ]),
                          _: 2
                        }, 1032, ["error"])
                      ]),
                      _: 2
                    }, 1024)
                  ]),
                  _cache[12] || (_cache[12] = _createElementVNode("hr", { class: "border-gray-400 my-6" }, null, -1)),
                  _createElementVNode("div", _hoisted_6, [
                    _withDirectives(_createElementVNode("div", _hoisted_7, [
                      _createVNode(_unref(Field), {
                        name: "LongTermTargetYear",
                        ref_key: "longTermTargetYearField",
                        ref: longTermTargetYearField
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([
                      errorMessageRef.value['LongTermTargetYear'] && 'is-error',
                      'inputfield',
                    ]),
                            error: errorMessage,
                            label: "Target Year"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_date_picker, _mergeProps(field, {
                                "model-value": convertToString(value),
                                type: "year",
                                editable: false,
                                clearable: false,
                                "disabled-date": disabledLongTermYear,
                                "value-format": "YYYY",
                                placeholder: "",
                                onChange: (value) => onValidateLongTermYear(value, field, values, setFieldValue)
                              }), null, 16, ["model-value", "onChange"]),
                              (errorMessageRef.value.LongTermTargetYear)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_8, _toDisplayString(errorMessageRef.value.LongTermTargetYear), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["class", "error"])
                        ]),
                        _: 2
                      }, 1536),
                      _createVNode(_unref(Field), {
                        name: "LongTermTargetValue",
                        ref_key: "longTermTargetValueField",
                        ref: longTermTargetValueField
                      }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([
                      errorMessageRef.value['LongTermTargetValue'] && 'is-warning',
                      'inputfield',
                    ]),
                            error: errorMessage,
                            label: "Renewable Percentage Target"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_9, [
                                _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, {
                                  "model-value": value,
                                  onInput: (value) => _unref(onValidateLongTermTarget)(value, field, values)
                                }), null, 16, ["model-value", "onInput"]),
                                _cache[9] || (_cache[9] = _createElementVNode("p", { class: "ml-3" }, "%", -1)),
                                _createVNode(_component_el_tooltip, {
                                  class: "absolute -top-10 right-0",
                                  effect: "light",
                                  placement: "bottom"
                                }, {
                                  content: _withCtx(() => [
                                    _withDirectives(_createElementVNode("textarea", {
                                      class: "w-72",
                                      rows: "10",
                                      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((TOOLTIP_DATA.LONG_TERM) = $event)),
                                      disabled: ""
                                    }, null, 512), [
                                      [_vModelText, TOOLTIP_DATA.LONG_TERM]
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("i", _hoisted_10, [
                                      _createVNode(BaseSvgIcon, {
                                        class: "inline-block w-5 h-5",
                                        name: "infoIcon"
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              (errorMessageRef.value.LongTermTargetValue)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_11, _toDisplayString(errorMessageRef.value.LongTermTargetValue), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["class", "error"])
                        ]),
                        _: 2
                      }, 1536)
                    ], 512), [
                      [_vShow, values.StandardType === 0 || values.StandardType === 1]
                    ]),
                    _withDirectives(_createElementVNode("div", _hoisted_12, [
                      _createVNode(_unref(Field), { name: "ShortTermTargetYear" }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([
                      errorMessageRef.value['ShortTermTargetYear'] && 'is-error',
                      'inputfield',
                    ]),
                            error: errorMessage,
                            label: "Short term Target Year (Optional)"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_date_picker, _mergeProps(field, {
                                "model-value": convertToString(value),
                                type: "year",
                                editable: false,
                                "disabled-date": disabledShortTermYear,
                                "value-format": "YYYY",
                                placeholder: "",
                                onChange: (value) => onValidateShortTermYear(value, field, values, setFieldValue)
                              }), null, 16, ["model-value", "onChange"]),
                              (errorMessageRef.value.ShortTermTargetYear)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(errorMessageRef.value.ShortTermTargetYear), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["class", "error"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_unref(Field), { name: "ShortTermTargetValue" }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([
                      errorMessageRef.value['ShortTermTargetValue'] && 'is-warning',
                      'inputfield',
                    ]),
                            error: errorMessage,
                            label: "Renewable Percentage (Optional)",
                            "model-value": value
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_14, [
                                _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, {
                                  "model-value": value,
                                  onInput: (value) => _unref(onValidateShortTermTarget)(value, field, values)
                                }), null, 16, ["model-value", "onInput"]),
                                _cache[10] || (_cache[10] = _createElementVNode("p", { class: "ml-3" }, "%", -1)),
                                _createVNode(_component_el_tooltip, {
                                  class: "absolute -top-10 right-0",
                                  effect: "light",
                                  placement: "bottom"
                                }, {
                                  content: _withCtx(() => [
                                    _withDirectives(_createElementVNode("textarea", {
                                      class: "w-72",
                                      rows: "10",
                                      "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((TOOLTIP_DATA.SHORT_TERM) = $event)),
                                      disabled: ""
                                    }, null, 512), [
                                      [_vModelText, TOOLTIP_DATA.SHORT_TERM]
                                    ])
                                  ]),
                                  default: _withCtx(() => [
                                    _createElementVNode("i", _hoisted_15, [
                                      _createVNode(BaseSvgIcon, {
                                        class: "inline-block w-5 h-5",
                                        name: "infoIcon"
                                      })
                                    ])
                                  ]),
                                  _: 1
                                })
                              ]),
                              (errorMessageRef.value.ShortTermTargetValue)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_16, _toDisplayString(errorMessageRef.value.ShortTermTargetValue), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["class", "error", "model-value"])
                        ]),
                        _: 2
                      }, 1024)
                    ], 512), [
                      [_vShow, values.StandardType === 1]
                    ]),
                    _withDirectives(_createElementVNode("div", null, [
                      _createVNode(_unref(Field), { name: "TrackingMethodType" }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: "inputfield",
                            error: errorMessage,
                            label: "Choose Scope 2 Tracking Methods"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_select, _mergeProps(field, {
                                "model-value": value,
                                class: "w-full",
                                placeholder: "Select",
                                onChange: ($event: any) => (handleCheckOpenSubmit(values))
                              }), {
                                default: _withCtx(() => [
                                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(getListTracking.value, (item) => {
                                    return (_openBlock(), _createBlock(_component_el_option, {
                                      key: item.Value,
                                      label: item.Name,
                                      value: item.Value
                                    }, null, 8, ["label", "value"]))
                                  }), 128))
                                ]),
                                _: 2
                              }, 1040, ["model-value", "onChange"]),
                              _createVNode(_component_el_tooltip, {
                                class: "absolute -top-10 right-0",
                                effect: "light",
                                placement: "bottom"
                              }, {
                                content: _withCtx(() => [
                                  _withDirectives(_createElementVNode("textarea", {
                                    class: "w-72",
                                    rows: "6",
                                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((TOOLTIP_DATA.TRACKING) = $event)),
                                    disabled: ""
                                  }, null, 512), [
                                    [_vModelText, TOOLTIP_DATA.TRACKING]
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("i", _hoisted_17, [
                                    _createVNode(BaseSvgIcon, {
                                      class: "inline-block w-5 h-5",
                                      name: "infoIcon"
                                    })
                                  ])
                                ]),
                                _: 1
                              })
                            ]),
                            _: 2
                          }, 1032, ["error"])
                        ]),
                        _: 2
                      }, 1024)
                    ], 512), [
                      [_vShow, values.StandardType]
                    ]),
                    _withDirectives(_createElementVNode("div", _hoisted_18, [
                      _createVNode(_unref(Field), { name: "BaseYear" }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: "inputfield",
                            error: errorMessage,
                            label: "Base Year"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_date_picker, _mergeProps(field, {
                                "model-value": convertToString(value),
                                type: "year",
                                editable: false,
                                clearable: false,
                                "disabled-date": disabledBaseYear,
                                "value-format": "YYYY",
                                placeholder: "",
                                onChange: ($event: any) => (handleCheckOpenSubmit(values))
                              }), null, 16, ["model-value", "onChange"])
                            ]),
                            _: 2
                          }, 1032, ["error"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_unref(Field), { name: "BaseYearTargetValue" }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: "inputfield",
                            error: errorMessage,
                            label: "Base Year Renewable Percentage"
                          }, {
                            default: _withCtx(() => [
                              _createElementVNode("div", _hoisted_19, [
                                _createVNode(_component_el_input, _mergeProps({ placeholder: "" }, field, {
                                  "model-value": value,
                                  onInput: ($event: any) => (handleCheckOpenSubmit(values))
                                }), null, 16, ["model-value", "onInput"]),
                                _cache[11] || (_cache[11] = _createElementVNode("span", { class: "ml-3" }, "%", -1))
                              ]),
                              (errorMessageRef.value.BaseYearTargetValue)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_20, _toDisplayString(errorMessageRef.value.BaseYearTargetValue), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["error"])
                        ]),
                        _: 2
                      }, 1024)
                    ], 512), [
                      [_vShow, values.StandardType === 1]
                    ]),
                    _withDirectives(_createElementVNode("div", _hoisted_21, [
                      _createVNode(_unref(Field), { name: "MonthOfTargetSubmission" }, {
                        default: _withCtx(({ value, field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: _normalizeClass([
                      errorMessageRef.value['MonthOfTargetSubmission'] && 'is-error',
                      'inputfield',
                    ]),
                            error: errorMessage,
                            label: "Month of SBTi target submission"
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_date_picker, _mergeProps(field, {
                                "model-value": convertToString(value),
                                type: "month",
                                editable: false,
                                clearable: false,
                                "disabled-date": 
                        (value) => disabledMonthSubmitted(value)
                      ,
                                format: "MM/YYYY",
                                placeholder: "",
                                onChange: (value) => handleMonthTarget(value, values)
                              }), null, 16, ["model-value", "disabled-date", "onChange"]),
                              _createVNode(_component_el_tooltip, {
                                class: "absolute -top-10 right-0",
                                effect: "light",
                                placement: "bottom"
                              }, {
                                content: _withCtx(() => [
                                  _withDirectives(_createElementVNode("textarea", {
                                    class: "w-72",
                                    rows: "10",
                                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((TOOLTIP_DATA.BASE_YEAR) = $event)),
                                    disabled: ""
                                  }, null, 512), [
                                    [_vModelText, TOOLTIP_DATA.BASE_YEAR]
                                  ])
                                ]),
                                default: _withCtx(() => [
                                  _createElementVNode("i", _hoisted_22, [
                                    _createVNode(BaseSvgIcon, {
                                      class: "inline-block w-5 h-5",
                                      name: "infoIcon"
                                    })
                                  ])
                                ]),
                                _: 1
                              }),
                              (errorMessageRef.value.MonthOfTargetSubmission)
                                ? (_openBlock(), _createElementBlock("div", _hoisted_23, _toDisplayString(errorMessageRef.value.MonthOfTargetSubmission), 1))
                                : _createCommentVNode("", true)
                            ]),
                            _: 2
                          }, 1032, ["class", "error"])
                        ]),
                        _: 2
                      }, 1024),
                      _createVNode(_unref(Field), { name: "IsSubmitted" }, {
                        default: _withCtx(({ field, errorMessage }) => [
                          _createVNode(_component_el_form_item, {
                            class: "mt-8",
                            error: errorMessage
                          }, {
                            default: _withCtx(() => [
                              _createVNode(_component_el_checkbox, _mergeProps(field, {
                                "model-value": isCheckSubmitted.value,
                                label: "My company has already submitted to SBTi",
                                size: "large",
                                onChange: (value) => handleCheckSubmitted(value, setFieldValue)
                              }), null, 16, ["model-value", "onChange"])
                            ]),
                            _: 2
                          }, 1032, ["error"])
                        ]),
                        _: 2
                      }, 1024)
                    ], 512), [
                      [_vShow, values.StandardType === 1]
                    ])
                  ])
                ]),
                _createElementVNode("div", _hoisted_24, [
                  _createElementVNode("button", {
                    class: "btn btn--black-primary",
                    onClick: _withModifiers(handleCancel, ["prevent"])
                  }, " Back "),
                  _createElementVNode("button", {
                    class: "btn btn--green-primary",
                    disabled: isSubmitting || !isOpenSubmit.value,
                    type: "submit"
                  }, " Publish New Target ", 8, _hoisted_25)
                ])
              ], 2)
            ]),
            _: 1
          }, 8, ["validation-schema", "initial-values"]))
        : _createCommentVNode("", true)
    ])), [
      [_directive_loading, loadingGetRenewableTargetDetail.value]
    ]),
    (dataDetail.value.StandardType || standardTypeSelected.value)
      ? _withDirectives((_openBlock(), _createElementBlock("div", _hoisted_26, [
          _createVNode(RenewableEnergyTargetTableBenchMark)
        ])), [
          [_directive_loading, loadingGetRenewableTargetDetail.value]
        ])
      : _createCommentVNode("", true)
  ]))
}
}

})