import { defineComponent as _defineComponent } from 'vue'
import DialogModule from "@/store/UI/Dialog";
import { onMounted, ref } from "vue";

export default /*@__PURE__*/_defineComponent({
  __name: 'DialogVerify',
  setup(__props, { expose: __expose }) {
  __expose();

const initialValues =
  DialogModule.getData ||
  ({
    title: "Please input title",
    description: "",
  } as any);

const handleClose = () => {
  DialogModule.hideModal();
};

const handleConfirm = () => {
  const { rest } = DialogModule.getRest as any;
  rest();
  DialogModule.hideModal();
};
const targetName = ref<any>([]);
const facilityName = ref<any>([]);
const countryName = ref<any>([]);
onMounted(() => {
  initialValues.facilityActivated.forEach((item: any) => {
    targetName.value.push(item.TargetName);
    item.FacilityName && facilityName.value.push(item.FacilityName);
    item.CountryName && countryName.value.push(item.CountryName);
  });
});

const __returned__ = { initialValues, handleClose, handleConfirm, targetName, facilityName, countryName, get DialogModule() { return DialogModule }, onMounted, ref }
Object.defineProperty(__returned__, '__isScriptSetup', { enumerable: false, value: true })
return __returned__
}

})