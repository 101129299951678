import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "box my-10 py-5" }
const _hoisted_2 = { class: "flex justify-between mb-6" }
const _hoisted_3 = { class: "flex justify-between items-center space-x-3 w-1/2" }

import { computed, onMounted, ref, watch } from "vue";
import RenewableEnergyTargetModule from "@ems/containers/RenewableEnergyTarget/RenewableEnergyTarget.module";
import { IBenchMarkDropdownResponseDTO } from "@/models/RenewableEnergyTarget";

export default /*@__PURE__*/_defineComponent({
  __name: 'index',
  setup(__props) {

const selectedCompany = ref("");
const selectedSection = ref("");
const selectedLocation = ref("");
const companies = ref<any>([]);
const sections = ref<any>([]);
const locations = ref<any>([]);
const dataSelect = ref<any>({
  locationSelect: "",
  sectorSelect: "",
  companySelect: "",
});

const dropdownSectors = (data: any) => {
  const newArrSectors = [] as any[];
  const newArrLocations = [] as any[];
  const newArrCompanies = [] as any[];
  data.map((x: IBenchMarkDropdownResponseDTO) => {
    sections.value = [];
    locations.value = [];
    newArrCompanies.push({
      value: x.CompanyName,
      label: x.CompanyName,
    });
    if (x.Sectors.length > 0) {
      x.Sectors.forEach((sector: string) => {
        newArrSectors.push({
          value: sector,
          label: sector,
        });
      });
    }
    if (x.Locations.length > 0) {
      x.Locations.forEach((location: string, index: number) => {
        newArrLocations.push({
          index: index,
          value: location,
          label: location,
        });
      });
    }
  });
  companies.value = [...newArrCompanies];
  locations.value = [
    ...new Map(newArrLocations.map((m) => [m.value, m])).values(),
  ];
  sections.value = [
    ...new Map(newArrSectors.map((m) => [m.value, m])).values(),
  ];
};
const dropdownCompanySectors = () => {
  const newArrSectors = [] as any[];
  const newArrCompanies = [] as any[];
  const newArrLocations = [] as any[];
  let data = [] as any;
  const dataDropDown = [
    ...RenewableEnergyTargetModule.dataBenchMarkDropDownGetter,
  ];
  if (dataSelect.value.locationSelect !== "") {
    const dataQuery = data.length > 0 ? data : dataDropDown;
    const arr = [] as any;
    dataQuery.filter((x: any) => {
      const dataFilter = x.Locations.filter(
        (localtion: any) => localtion == dataSelect.value.locationSelect
      );
      if (dataFilter.length > 0) {
        arr.push(x);
        data = arr;
      }
    });
  } else {
    const dataQuery = conditionCheckEmptySelect() ? dataDropDown : data;
    const arr = [] as any;
    dataQuery.forEach((x: any) => {
      arr.push(x);
      data = arr;
    });
  }
  if (dataSelect.value.sectorSelect !== "") {
    const arr = [] as any;
    const dataQuery = data.length > 0 ? data : dataDropDown;
    dataQuery.filter((x: any) => {
      const dataFilter = x.Sectors.filter(
        (z: any) => z == dataSelect.value.sectorSelect
      );
      if (dataFilter.length > 0) {
        arr.push(x);
        data = arr;
      }
    });
  } else {
    const dataQuery = conditionCheckEmptySelect() ? dataDropDown : data;
    const arr = [] as any;
    dataQuery.forEach((x: any) => {
      arr.push(x);
      data = arr;
    });
  }
  if (dataSelect.value.companySelect !== "") {
    const arr = [] as any;
    const dataQuery = data.length > 0 ? data : dataDropDown;
    dataQuery.filter((x: any) => {
      if (x.CompanyName === dataSelect.value.companySelect) {
        arr.push(x);
        data = arr;
      }
    });
  } else {
    const dataQuery = conditionCheckEmptySelect() ? dataDropDown : data;
    const arr = [] as any;
    dataQuery.forEach((x: any) => {
      arr.push(x);
      data = arr;
    });
  }
  data.map((x: IBenchMarkDropdownResponseDTO, index: number) => {
    companies.value = [];
    locations.value = [];
    sections.value = [];
    newArrCompanies.push({
      index: index,
      value: x.CompanyName,
      label: x.CompanyName,
    });

    x.Sectors.forEach((sector: string) => {
      newArrSectors.push({
        index: index,
        value: sector,
        label: sector,
      });
    });
    x.Locations.forEach((location: string) => {
      newArrLocations.push({
        index: index,
        value: location,
        label: location,
      });
    });
  });

  companies.value = newArrCompanies;
  sections.value = [
    ...new Map(newArrSectors.map((m) => [m.value, m])).values(),
  ];
  locations.value = [
    ...new Map(newArrLocations.map((m) => [m.value, m])).values(),
  ];
};

const conditionCheckEmptySelect = () => {
  return (
    dataSelect.value.locationSelect === "" &&
    dataSelect.value.sectorSelect === "" &&
    dataSelect.value.companySelect === ""
  );
};
const handleClear = (key: string) => {
  switch (key) {
    case "location":
      dataSelect.value.locationSelect = "";
      break;
    case "sector":
      dataSelect.value.sectorSelect = "";
      break;
    default:
      dataSelect.value.companySelect = "";
      break;
  }
  dropdownCompanySectors();
};
const dataRenewableTargetBenchMark = computed(
  () => RenewableEnergyTargetModule.dataRenewableTargetBenchMarkGetter
);
const isLoadingRenewableTargetBenchMark = computed(
  () => RenewableEnergyTargetModule.isLoadingRenewableTargetBenchMarkGetter
);
const dataRenewableTargetBenchMarkPagination = computed(
  () => RenewableEnergyTargetModule.dataRenewableTargetBenchMarkPaginationGetter
);
const disabled = ref(false);
const small = ref(false);
const background = ref(true);
const currentPage = ref(1);
const pageSize = ref(10);

onMounted(() => {
  getRenewableTargetBenchMark(1);
  RenewableEnergyTargetModule.getRenewableTargetBenchMarkDropDown();
});
const dataBenchMarkDropDown = computed(
  () => RenewableEnergyTargetModule.dataBenchMarkDropDownGetter
);
watch(dataBenchMarkDropDown, () => {
  dropdownSectors(RenewableEnergyTargetModule.dataBenchMarkDropDownGetter);
});

const handleSearch = () => {
  const params = {
    CompanyName: dataSelect.value.companySelect,
    Location: dataSelect.value.locationSelect,
    Sector: dataSelect.value.sectorSelect,
  };
  getRenewableTargetBenchMark(1, params);
};
const getRenewableTargetBenchMark = (currentPage?: number, params?: any) => {
  RenewableEnergyTargetModule.getRenewableTargetBenchMark({
    ...params,
    CurrentPage: currentPage,
    Limit: pageSize.value,
  });
};
const handleSizeChange = (val: number) => {
  getRenewableTargetBenchMark(1);
  currentPage.value = 1;
};
const handleCurrentChange = (val: number) => {
  getRenewableTargetBenchMark(val);
};
const handleChangeLocation = (value: string) => {
  selectedLocation.value = value;
  dataSelect.value.locationSelect = value;
  dropdownCompanySectors();
};
const handleChangeSector = (value: string) => {
  selectedSection.value = value;
  dataSelect.value.sectorSelect = value;
  dropdownCompanySectors();
};
const handleChangeCompany = (value: string) => {
  selectedCompany.value = value;
  dataSelect.value.companySelect = value;
  dropdownCompanySectors();
};

return (_ctx: any,_cache: any) => {
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_el_table = _resolveComponent("el-table")!
  const _component_el_pagination = _resolveComponent("el-pagination")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _cache[11] || (_cache[11] = _createElementVNode("div", null, [
        _createElementVNode("p", { class: "text-lg 2xl:text-xl font-bold" }, "SBTi Progress Reference"),
        _createElementVNode("p", { class: "text-lg 2xl:text-xl" }, "for Renewable Electricity")
      ], -1)),
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", null, [
          _cache[8] || (_cache[8] = _createElementVNode("p", null, "Company", -1)),
          _createVNode(_component_el_select, {
            class: _normalizeClass(_ctx.styles['custom-input']),
            "model-value": selectedCompany.value,
            placeholder: "All Company",
            onChange: _cache[0] || (_cache[0] = (value) => handleChangeCompany(value)),
            clearable: true,
            onClear: _cache[1] || (_cache[1] = () => handleClear('company')),
            filterable: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(companies.value, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  class: "flex items-center",
                  key: item.value,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["class", "model-value"])
        ]),
        _createElementVNode("div", null, [
          _cache[9] || (_cache[9] = _createElementVNode("p", null, "Sector", -1)),
          _createVNode(_component_el_select, {
            class: _normalizeClass(_ctx.styles['custom-input']),
            "model-value": selectedSection.value,
            placeholder: "All Sector",
            onChange: _cache[2] || (_cache[2] = (value) => handleChangeSector(value)),
            clearable: true,
            onClear: _cache[3] || (_cache[3] = () => handleClear('sector')),
            filterable: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(sections.value, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  class: "flex items-center",
                  key: item.index,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["class", "model-value"])
        ]),
        _createElementVNode("div", null, [
          _cache[10] || (_cache[10] = _createElementVNode("p", null, "Location", -1)),
          _createVNode(_component_el_select, {
            class: _normalizeClass(_ctx.styles['custom-input']),
            "model-value": selectedLocation.value,
            placeholder: "All Location",
            onChange: _cache[4] || (_cache[4] = (value) => handleChangeLocation(value)),
            clearable: true,
            onClear: _cache[5] || (_cache[5] = () => handleClear('location')),
            filterable: true
          }, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(locations.value, (item) => {
                return (_openBlock(), _createBlock(_component_el_option, {
                  class: "flex items-center",
                  key: item.index,
                  label: item.label,
                  value: item.value
                }, null, 8, ["label", "value"]))
              }), 128))
            ]),
            _: 1
          }, 8, ["class", "model-value"])
        ])
      ]),
      _createElementVNode("div", { class: "flex justify-end items-center space-x-3" }, [
        _createElementVNode("button", {
          type: "button",
          class: "btn btn--green-primary w-32",
          onClick: handleSearch
        }, " Search ")
      ])
    ]),
    _createVNode(_component_el_table, {
      class: "w-full table-custom mb-3",
      stripe: "",
      data: dataRenewableTargetBenchMark.value,
      height: "66vh",
      "cell-class-name": "text-cafe-dark",
      "header-row-class-name": "tableHeaderRow",
      "row-class-name": "tableRow"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "CompanyName",
          label: "COMPANY"
        }),
        _createVNode(_component_el_table_column, {
          prop: "Sector",
          label: "SECTOR"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.Sector || "All"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "Location",
          label: "LOCATION"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(scope.row.Location || "All"), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "TargetTimeFrame",
          label: "TARGET TIME FRAME"
        }),
        _createVNode(_component_el_table_column, {
          prop: "TargetValue",
          label: "TARGET VALUE",
          width: "80"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(`${scope.row.TargetValue ? scope.row.TargetValue + "%" : ""}`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "TargetCompletion",
          label: "TARGET COMPLETION"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(`${
                scope.row.TargetCompletion
                  ? scope.row.TargetCompletion + "%"
                  : ""
              }`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          width: "250",
          prop: "TargetElapsedTimeFrame",
          label: "TARGET ELAPSED TIMEFRAME"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(`${
                scope.row.TargetElapsedTimeFrame
                  ? scope.row.TargetElapsedTimeFrame + "%"
                  : ""
              }`), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "ReportingYear",
          label: "REPORTING YEAR"
        }),
        _createVNode(_component_el_table_column, {
          prop: "CurrentTargetStatus",
          label: "CURRENT TARGET STATUS",
          width: "200"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("p", null, _toDisplayString(`${
                scope.row.CurrentTargetStatus
                  ? scope.row.CurrentTargetStatus + "%"
                  : ""
              }`), 1)
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["data"]),
    _createVNode(_component_el_pagination, {
      currentPage: currentPage.value,
      "onUpdate:currentPage": _cache[6] || (_cache[6] = ($event: any) => ((currentPage).value = $event)),
      "page-size": pageSize.value,
      "onUpdate:pageSize": _cache[7] || (_cache[7] = ($event: any) => ((pageSize).value = $event)),
      "page-sizes": [10, 20, 30, 40],
      small: small.value,
      disabled: disabled.value,
      background: background.value,
      layout: "sizes, prev, pager, next",
      total: dataRenewableTargetBenchMarkPagination.value.TotalRow,
      onSizeChange: handleSizeChange,
      onCurrentChange: handleCurrentChange
    }, null, 8, ["currentPage", "page-size", "small", "disabled", "background", "total"])
  ]))
}
}

})